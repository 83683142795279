import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/components/biz/pages/users/ais/store";
import {setThread, setIsPrivateChat} from "@/components/biz/pages/users/ais/ChatSlice";
import {toast} from "react-toastify";
import {updateThreadPrivacy} from "@/libs/api/users/threads";

const PrivateToggle: React.FC = () => {
  const dispatch = useDispatch();
  const thread = useSelector((state: RootState) => state.chat.thread);
  const user = useSelector((state: RootState) => state.chat.user);
  const isPrivateChat = useSelector((state: RootState) => state.chat.isPrivateChat);

  const [isPrivate, setIsPrivate] = useState(thread?.isPrivate ?? isPrivateChat);
  const [canToggle, setCanToggle] = useState(false);

  useEffect(() => {
    setCanToggle(thread?.userId === user?.id || !thread);
  }, [thread?.userId, user?.id]);

  useEffect(() => {
    if (thread?.isPrivate !== undefined) {
      setIsPrivate(thread.isPrivate);
    }
  }, [thread?.isPrivate]);

  const onToggle = async (newIsPrivate: boolean) => {
    if (!thread?.id) return;

    try {
      const response = await updateThreadPrivacy(thread.id, newIsPrivate);
      dispatch(setThread(response.data));
      dispatch(setIsPrivateChat(response.data.isPrivate));
      toast.success(`スレッドを${newIsPrivate ? "非公開" : "公開"}にしました`);
    } catch (error) {
      console.error("Error updating privacy:", error);
      toast.error("権限の変更に失敗しました");
    }
  };

  return (
    <>
      {canToggle && (
        <div className="flex border border-gray-300 rounded-lg overflow-hidden w-40 h-full">
          <input
            type="radio"
            id="public"
            name="visibility"
            className="hidden peer"
            checked={!isPrivate}
            onChange={() => onToggle(false)}
          />
          <label
            htmlFor="public"
            className={`flex-1 text-center cursor-pointer flex items-center justify-center ${
              !isPrivate ? "bg-green-600 text-white font-bold" : "bg-white text-black font-bold"
            }`}
          >
            公開
          </label>

          <input
            type="radio"
            id="private"
            name="visibility"
            className="hidden peer"
            checked={Boolean(isPrivate)}
            onChange={() => onToggle(true)}
          />
          <label
            htmlFor="private"
            className={`flex-1 text-center cursor-pointer flex items-center justify-center ${
              isPrivate ? "bg-gray-500 text-white font-bold" : "bg-white text-black font-bold"
            }`}
          >
            非公開
          </label>
        </div>
      )}
    </>
  );
};

export default PrivateToggle;
