import React, { useState, useMemo, useRef, useEffect } from "react";
import { createConsumer } from "@rails/actioncable";
import { WhisperAi } from "@/interfaces/whisper_ai";
import { User } from "@/interfaces/user";
import { InputField } from "@/interfaces/input_field";
import MovieUploadForm from "../../users/ais/whisper/MovieUploadForm";
import PromptSettingForm from "../../users/ais/chat/PromptSettingForm";
import AiMessage from "../../users/ais/chat/messages/Ai";
import { AdMovie } from "@/interfaces/ad_movie";
import RegisterAlert from "../../../components/partials/modals/RegisterAlert";

import FreeSpeedLimit from "../../users/ais/chat/messages/FreeSpeedLimit";

type Props = {
  whisperAi: WhisperAi;
  inputFields: InputField[];
  adCount: number;
  adMovies: AdMovie[];
  user: User;
};

const UsersWhisperAisShow: React.FC<Props> = (props) => {
  const [uploading, setUploading] = useState(false);
  const [transcribeText, setTranscribeText] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const cable = useMemo(() => createConsumer(), []);

  const onSubmitMovieUploadForm = async (datas: any) => {
    console.log("todo");
    setModalOpen(true);
  };

  const cancelStreaming = () => {
    console.log("canceled");
  };

  const onSubmitPromptSettingForm = async (data: any) => {};

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:roudned-md border">
        <MovieUploadForm
          submit={(datas) => onSubmitMovieUploadForm(datas)}
          uploading={uploading}
          remainGenerateCap={props.adCount}
        />
      </div>

      {transcribeText != null && (
        <>
          <div className="p-4 mt-4 rounded-lg w-full inline-block rounded-tl-none text-gray-800 whitespace-pre-wrap break-words">
            <AiMessage
              message={transcribeText}
              ai={props.whisperAi.ai}
              streaming={false}
              showAction={true}
              hideActionEditor={true}
            />
            <div className="mt-10 border">
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                aiForms={props.inputFields}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                user={props.user}
              />
            </div>
          </div>
        </>
      )}

      <RegisterAlert open={modalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default UsersWhisperAisShow;
