import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DOMPurify from "dompurify";
import {createConsumer, Channel} from "@rails/actioncable";
import SpeakMessages from "@/components/biz/pages/users/ais/SpeakMessages";
import {RootState} from "@/components/biz/pages/users/ais/store";
import {
  addSpeakResponse,
  hideChat,
  stopGenerating,
  startGenerating,
  setThread,
  clearAllSpeakMessages,
  addSpeakMessage,
  setUserHistories,
  setOtherHistories,
  addSpeakReferences,
  resetInitialFormValues,
  resetToken,
  clearToken, setToken, deleteSpeakMessage, clearSpeakMessageResponse, setIsPrivateChat,
} from "@/components/biz/pages/users/ais/ChatSlice";
import {usersOthersTextGenerateHistories, usersTextGenerateHistories} from "@/libs/api/users/users_ai";
import {toast} from "react-toastify";
import PrivateToggle from "@/components/biz/pages/users/ais/PrivateToggle";

const ChatComponent: React.FC = () => {
  const dispatch = useDispatch();
  const isChatVisible = useSelector((state: RootState) => state.chat.isChatVisible);
  const chatChannelRef = useRef<Channel | null>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const isGenerating = useSelector((state: RootState) => state.chat.isGenerating);
  const initialFormValues = useSelector<RootState, Record<string, string>>(
    (state) => state.chat.initialFormValues
  );
  const ai = useSelector((state: RootState) => state.chat.ai);
  const user = useSelector((state: RootState) => state.chat.user);
  const thread = useSelector((state: RootState) => state.chat.thread);
  const aiForms = useSelector((state: RootState) => state.chat.aiForms);
  const speakMessages = useSelector((state: RootState) => state.chat.speakMessages);
  const token = useSelector((state: RootState) => state.chat.token);
  const tokenRef = useRef<string>(token);
  const isChatAllowed = useSelector((state: RootState) => state.chat.isChatAllowed);
  const isChatAllowedRef = useRef<boolean>(isChatAllowed);
  const isPrivateChat = useSelector((state: RootState) => state.chat.isPrivateChat);

  useEffect(() => {
    tokenRef.current = token;
  }, [token]);
  useEffect(() => {
    const channelToken = createFormToken();
    const cable = createConsumer();
    chatChannelRef.current = cable.subscriptions.create(
      {channel: "Biz::PrivateChatChannel", token: channelToken},
      {
        connected() {
          if (thread === null) {
            firstCreate();
          }
        },
        disconnected() {
        },
        received(response) {
          switch (response.status) {
            case "file_uploading":
              dispatch(addSpeakResponse({token: tokenRef.current, response: response.data}));
              break;
            case "reset":
              dispatch(clearSpeakMessageResponse(tokenRef.current));
              break;
            case "streaming":
              dispatch(addSpeakResponse({token: tokenRef.current, response: response.data}));
              break;
            case "finish":
              const {generationResult} = response;
              const {retrievedReferences} = generationResult;
              dispatch(setThread(thread));
              if (retrievedReferences) {
                const references = retrievedReferences
                  .map((ref) => ref.filename && ref.downloadUrl ? {
                    filename: ref.filename,
                    downloadUrl: ref.downloadUrl
                  } : null)
                  .filter(Boolean);
                dispatch(addSpeakReferences({token: tokenRef.current, references}));
              }
              // FIXME: 以下重複コード
              usersTextGenerateHistories(ai.slug).then((res) => {
                dispatch(setUserHistories(res.data));
              });
              usersOthersTextGenerateHistories(ai.slug).then((res) => {
                dispatch(setOtherHistories(res.data));
              });
              dispatch(stopGenerating());
              break;
            case "error":
              alert(response.error);
              toast.error('申し訳ございません、問題が発生しました');
              if (response.errorCode == "upgrade") {
                location.reload();
              }
              break;
          }
        },
      }
    );
    return () => {
      if (chatChannelRef.current) {
        chatChannelRef.current.unsubscribe();
        chatChannelRef.current = null;
        dispatch(setThread({}));
        dispatch(clearToken());
      }
    };
  }, [dispatch]);
  const firstCreate = () => {
    dispatch(startGenerating());
    const summaryInput = createSummaryInput();
    const sanitizedSummaryInput = DOMPurify.sanitize(summaryInput);

    dispatch(addSpeakMessage({token: token, input: sanitizedSummaryInput}));
    chatChannelRef.current.perform("stream_message",
      {
        ai_slug: ai.slug,
        user_token: user.token,
        form_data: initialFormValues,
        token: token,
        private: isPrivateChat,
        thread_id: null,
      });
  }

  const createFormToken = () => (crypto.randomUUID === undefined) ? generateRandomString(20) : crypto.randomUUID();

  const generateRandomString = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  const createSummaryInput = (): string => {
    return aiForms.map((form) => {
      const fieldValue = initialFormValues[form.inputName];
      return `<b>${form.label}</b><br>${fieldValue}<br>`;
    }).filter(Boolean)
      .join("");
  };

  const handleGenerate = () => {
    const newToken = createFormToken();
    dispatch(setToken(newToken));
    dispatch(startGenerating());
    if (messageInputRef.current) {
      const inputValue = messageInputRef.current.value;
      dispatch(addSpeakMessage({token: newToken, input: inputValue}));
      let form_data = Object.assign({}, initialFormValues);
      form_data["add_input"] = inputValue;
      chatChannelRef.current?.perform("stream_message",
        {
          ai_slug: ai.slug,
          user_token: user.token,
          token: newToken,
          thread_id: thread.id,
          form_data: form_data,
          private: isPrivateChat,
        });
      messageInputRef.current.value = "";
      usersTextGenerateHistories(ai.slug).then((res) => {
        dispatch(setUserHistories(res.data));
      });
      usersOthersTextGenerateHistories(ai.slug).then((res) => {
        dispatch(setOtherHistories(res.data));
      });
    }
  };

  const clearAdditionalPromptForm = () => {
    if (messageInputRef.current) {
      messageInputRef.current.value = "";
    }
  };

  const createFormFieldValues = (inputElement: HTMLInputElement): { [key: string]: string | null } => {
    const formDatas: { [key: string]: string | null } = {};
    formDatas["add_input"] = inputElement.value || null;
    return formDatas;
  };

  const copy = () => {
    dispatch(clearAllSpeakMessages());
    dispatch(hideChat());
  }

  const reset = () => {
    dispatch(clearAllSpeakMessages());
    dispatch(resetInitialFormValues());
    dispatch(hideChat());
  }

  return (
    <div className="flex flex-col grow border-r border-gray-200 bg-gray-100">
      <div className="flex justify-end h-[76px] border-b border-gray-300 p-4 bg-white space-x-2">
        <PrivateToggle />
        <button
          type="button"
          onClick={copy}
          className="px-4 rounded bg-cwaipurple-600 text-white font-semibold hover:opacity-90"
        >
          複製
        </button>
        <button
          type="button"
          onClick={reset}
          className="px-4 rounded bg-cwaipurple-600 text-white font-semibold hover:opacity-90"
        >
          新規作成
        </button>
      </div>
      <div className="p-4 flex flex-col grow">
        <SpeakMessages />
        {isChatAllowedRef.current ? (
          <div className="flex flex-col justify-end w-full sticky bottom-0 z-[1000] rounded-lg">
            <div className="bg-white border border-gray-300 rounded-lg py-3 px-4">
              <div className="flex">
                {/* フォーム */}
                <div className="grow">
                <textarea
                  ref={messageInputRef}
                  placeholder="追加指示を入力(enterで改行)"
                  className="w-full rounded p-2 focus:outline-none resize-none"
                  style={{ fieldSizing: 'content', maxHeight: '10lh'}}
                />
                </div>
              </div>

              {/* 送信ボタン */}
              <div className="">
                {isGenerating ? (
                  <div className="text-right">
                    <span className="text-sm text-gray-500">生成中...</span>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={clearAdditionalPromptForm}
                      className="mr-2 px-3 py-2 border border-gray-300 rounded  hover:opacity-90"
                    >
                      <span className="text-md font-semibold">クリア</span>
                    </button>
                    <button
                      type="button"
                      onClick={handleGenerate}
                      className="px-4 py-2 rounded bg-cwaipurple-600 shadow-sm hover:opacity-90 text-white"
                    >
                      <span className="text-md font-semibold">生成</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatComponent;
