import React, { useState, useMemo, useRef, useEffect } from "react";
import { createConsumer } from "@rails/actioncable";
import { WhisperAi } from "../../../../interfaces/whisper_ai";
import { User } from "../../../../interfaces/user";
import { UserPlan } from "../../../../interfaces/user_plan";
import { InputField } from "../../../../interfaces/input_field";
import { transcribeMovie } from "../../../../libs/api/users/whisper";
import { toast } from "react-toastify";
import MovieUploadForm from "../../../users/ais/whisper/MovieUploadForm";
import PromptSettingForm from "../../../users/ais/chat/PromptSettingForm";
import AiMessage from "../../../users/ais/chat/messages/Ai";
import UpgradeAlert from "../../../../components/partials/modals/UpgradeAlert";
import NavBar from "../../../partials/navs/Bar";
import { checkEmailConfirmStatus } from "../../../../libs/api/user";
import EmailConfirm from "../../../../components/partials/modals/EmailConfirm";
import { AdMovie } from "../../../../interfaces/ad_movie";
import RewardModal from "../../../partials/modals/Reward";
import FreeSpeedLimit from "../../../users/ais/chat/messages/FreeSpeedLimit";

type Props = {
  whisperAi: WhisperAi;
  inputFields: InputField[];
  adCount: number;
  adMovies: AdMovie[];
  user: User;
  userPlan: UserPlan;
};

const UsersWhisperAisShow: React.FC<Props> = (props) => {
  const [uploading, setUploading] = useState(false);
  const [transcribeText, setTranscribeText] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [currentWhisperGenerateDuration, setCurrentWhisperGenerateDuration] =
    useState<number>(props.user.currentWhisperGenerateDuration);
  const [currentTextGenerateCount, setCurrentTextGenerateCount] = useState(
    props.user.currentGenerateCount
  );
  const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false);
  const [openPremiumOnly, setOpenPremiumOnly] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const cable = useMemo(() => createConsumer(), []);

  console.log("props.adMovies", props.adMovies);

  const onSubmitMovieUploadForm = async (datas: any) => {
    if (currentWhisperGenerateDuration >= props.user.whisperGenerateLimit) {
      setUpgradeModalOpen(true);
    } else {
      setUploading(true);
      // FormData オブジェクトの作成
      const formData = new FormData();
      // "movieFile" というキーでファイルを追加
      formData.append("movieFile", datas["movieFile"]);
      formData.append("videoUrl", datas["videoUrl"]);
      formData.append("whisperAiId", props.whisperAi.id);

      try {
        // FormData を使用してリクエストを送信
        let { data } = await transcribeMovie(formData);
        setUploading(false);
        setTranscribeText(data.text);
        toast.success("動画を文字起こししました");
        console.log(
          "currentWhisperGenerateDuration",
          currentWhisperGenerateDuration
        );
        console.log("parseFloat(data.duration)", parseFloat(data.duration));
        setCurrentWhisperGenerateDuration(
          (prev) => prev + parseFloat(data.duration)
        );
      } catch (error) {
        setUploading(false);
      }
    }
  };

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const isTextFree = () => {
    if (props.user) {
      return !props.user.isPaidText;
    } else {
      return false;
    }
  };

  const isBasic = () => {
    return props.user.planName === "basic";
  };

  const submitForm = (data) => {
    setStreaming(true);
    setCurrentTextGenerateCount(currentTextGenerateCount + 1);
    data["transcribe_text"] = transcribeText;
    // ここでランダムの文字列生成
    const token = generateRandomString(20);
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: token },
      {
        connected: () => {
          sub.perform("stream_message", {
            ai_slug: props.whisperAi.ai.slug,
            form_data: data,
            token: token,
            user_token: props.user.token,
          });
        },
        received: (res) => {
          if (res.error) {
            alert(res.error);
            setMessage("申し訳ございません、問題が発生しました");
            if (res?.errorCode == "upgrade") {
              location.reload();
            }
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false);
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        },
      }
    );
    setSubscription(sub);
    setMessage("");
  };

  const onSubmitPromptSettingForm = async (data: any) => {
    if (currentTextGenerateCount >= props.user.generateLimit && isBasic()) {
      setUpgradeModalOpen(true);
      return;
    } else if (isTextFree() && !props.user.emailConfirmed) {
      let res = await checkEmailConfirmStatus();
      if (!res.data.emailConfirmed) {
        setEmailConfirmModalOpen(true);
      }
    } else if (
      isTextFree() &&
      props.whisperAi.ai.paidFlag &&
      currentTextGenerateCount >= 5
    ) {
      setOpenPremiumOnly(true);
      return;
    } else if (isTextFree() && currentTextGenerateCount >= 5) {
      setFormData(data);
      setTimeout(() => {
        setShowRewardModal(true);
      }, 100);
    } else {
      submitForm(data);
    }
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
      setSubscription(null);
    }
  };

  return (
    <>
      <div className="w-full inline-block mb-6">
        <NavBar
          count={currentWhisperGenerateDuration}
          setCount={setCurrentWhisperGenerateDuration}
          limit={props.user.whisperGenerateLimit}
          isFree={!props.user.isPaidWhisper}
          unit={`分`}
          resetAt={props.userPlan?.nextPlanUpdatedAt}
        />
      </div>
      <div className="shadow sm:overflow-hidden sm:roudned-md border">
        <MovieUploadForm
          submit={(datas) => onSubmitMovieUploadForm(datas)}
          uploading={uploading}
          remainGenerateCap={props.adCount}
        />
      </div>

      {transcribeText != null && (
        <>
          <div className="p-4 mt-4 rounded-lg w-full inline-block rounded-tl-none text-gray-800 whitespace-pre-wrap break-words">
            <AiMessage
              message={transcribeText}
              ai={props.whisperAi.ai}
              streaming={false}
              showAction={true}
              hideActionEditor={true}
            />
            <div className="mt-10 border">
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                aiForms={props.inputFields}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                user={props.user}
              />
            </div>
          </div>
        </>
      )}
      {message != "" && (
        <div className="mt-10">
          {!props.user.isPaidText && <FreeSpeedLimit user={props.user} />}
          <div className="mt-6">
            <AiMessage
              message={message}
              ai={props.whisperAi.ai}
              streaming={streaming}
            />
          </div>
        </div>
      )}
      {streaming && (
        <>
          <div className="fixed bottom-0 left-0 w-full flex justify-center">
            <button
              type="button"
              className="inline-flex mb-5 justify-center items-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={cancelStreaming}
            >
              <i className="fa-solid fa-stop mr-2"></i>
              <span>生成をストップ</span>
            </button>
          </div>
        </>
      )}
      <UpgradeAlert
        open={upgradeModalOpen}
        setOpen={setUpgradeModalOpen}
        title={`文字起こし上限に達しました`}
        message={`現状のプランでは文字起こしが「${props.user.whisperGenerateLimit}分」までとなっております。以下のボタンから文字起こしプレミアムプラン加入ページに移動し、アップグレードしてください`}
        redirectLink={`/users/plans/whispers`}
        cta={`文字起こしプレミアムプランを見る`}
      />
      <UpgradeAlert
        open={openPremiumOnly}
        setOpen={setOpenPremiumOnly}
        title={`こちらの生成ツールはpremiumプラン限定です。`}
        message={`以下のボタンからプラン選択ページに移動し、有料プランにアップグレードしてください`}
      />
      <EmailConfirm
        open={emailConfirmModalOpen}
        setOpen={setEmailConfirmModalOpen}
        title={`メールアドレスの確認をお願いします`}
        message={`ご登録頂いたメールアドレスに、登録確認URLを送信いたしましたのでURLにアクセス後、再度機能をご利用ください`}
      />
      <RewardModal
        open={showRewardModal}
        setOpen={setShowRewardModal}
        onMovieComplete={() => {
          submitForm(formData);
        }}
        adCount={props.adCount}
        adMovies={props.adMovies}
      />
    </>
  );
};

export default UsersWhisperAisShow;
