import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {AiForm} from "@/interfaces/ai_form";
import {Ai} from "@/interfaces/ai";
import {User} from "@/interfaces/user";
import {Thread} from "@/interfaces/biz/users_ai";

export interface Reference {
  filename: string;
  downloadUrl: string;
}

export interface SpeakMessage {
  input: string;
  responses: string[];
  references?: Reference[];
}

interface ChatState {
  initialFormValues: Record<string, string>;
  speakMessages: Record<string, SpeakMessage>;
  speakInputValue: string;
  // FIXME: booleanじゃなくて複数Component切り替えできるようにしたい
  isChatVisible: boolean;
  isLoading: boolean;
  isGenerating: boolean;
  formToken: string;
  ai: Ai;
  aiForms: AiForm[];
  user: User;
  thread: Thread;
  userHistories: { date: string; threads: Thread[] }[];
  otherHistories: { date: string; threads: Thread[] }[];
  token: string;
  isChatAllowed: boolean;
  isPrivateChat: boolean;
}

const initialState: ChatState = {
  speakMessages: {},
  initialFormValues: {},
  isChatVisible: false,
  speakInputValue: "",
  isLoading: false,
  isGenerating: false,
  formToken: "",
  ai: null,
  aiForms: [],
  user: null,
  thread: null,
  userHistories: [],
  otherHistories: [],
  token: "",
  isChatAllowed: true,
  isPrivateChat: false,
};

const aiReducers = {
  setAi: (state: ChatState, action: PayloadAction<Ai>) => {
    state.ai = action.payload;
  }
}

const aiFormReducers = {
  setAiForms: (state: ChatState, action: PayloadAction<AiForm[]>) => {
    state.aiForms = action.payload;
  }
}

const userReducers = {
  setUser: (state: ChatState, action: PayloadAction<User>) => {
    state.user = action.payload;
  }
}

const threadReducers = {
  setThread: (state: ChatState, action: PayloadAction<Thread>) => {
    state.thread = action.payload;
  }
}
const logsReducers = {
  setUserHistories: (
    state: ChatState,
    action: PayloadAction<{ date: string; threads: Thread[] }[]>
  ) => {
    state.userHistories = action.payload;
  },
  setOtherHistories: (
    state: ChatState,
    action: PayloadAction<{ date: string; threads: Thread[] }[]>
  ) => {
    state.otherHistories = action.payload;
  },
  clearLogs: (state: ChatState) => {
    state.userHistories = [];
    state.otherHistories = [];
  },
};

const formTokenReducers = {
  setFormToken: (state: ChatState, action: PayloadAction<string>) => {
    state.formToken = action.payload;
  }
}

const isLoadingReducers = {
  setIsLoading: (state: ChatState, action: PayloadAction<boolean>) => {
    state.isLoading = action.payload;
  }
}
const isCreatingReducers = {
  startGenerating(state) {
    state.isGenerating = true;
  },
  stopGenerating(state) {
    state.isGenerating = false;
  },
}

const generateSummaryFromInputs = (inputs: AiForm[]): string => {
  return inputs.map((input) => input.inputName).filter(Boolean).join(", ");
};

const speakInputValueReducers = {
  setSpeakInputValue: (state: ChatState, action: PayloadAction<string>) => {
    state.speakInputValue = action.payload;
  }
}

const initialFormValuesReducers = {
  setInitialFormValues: (state: ChatState, action: PayloadAction<Record<string, string>>) => {
    state.initialFormValues = action.payload;
  },
  resetInitialFormValues: (state: ChatState) => {
    state.initialFormValues = {};
  },
}

const speakMessagesReducers = {
  clearAllSpeakMessages: (state: ChatState) => {
    state.speakMessages = {};
  },
  addSpeakMessage: (
    state: ChatState,
    action: PayloadAction<{ token: string; input: string }>
  ) => {
    const {token, input} = action.payload;
    state.speakMessages[token] = {input, responses: []};
  },
  addSpeakResponse: (
    state: ChatState,
    action: PayloadAction<{ token: string; response: string }>
  ) => {
    const {token, response} = action.payload;
    const message = state.speakMessages[token];
    if (message) {
      message.responses.push(response);
    }
  },
  addSpeakInputAndResponse: (
    state: ChatState,
    action: PayloadAction<{ token: string; input: string; response: string[]; }>
  ) => {
    const {token, input, response} = action.payload;
    state.speakMessages[token] = {input, responses: response, references: []};
  },
  addSpeakReferences: (
    state: ChatState,
    action: PayloadAction<{ token: string; references: Reference[] }>
  ) => {
    const {token, references} = action.payload;

    const message = state.speakMessages[token];
    if (message) {
      if (message.references) {
        message.references.push(...references);
      } else {
        message.references = [...references];
      }
    } else {
      console.warn(`Token "${token}" に該当する SpeakMessage が見つかりません`);
    }
  },
  deleteSpeakMessage: (
    state: ChatState,
    action: PayloadAction<string>
  ) => {
    const token = action.payload;
    if (state.speakMessages[token]) {
      delete state.speakMessages[token];
    }
  },
  clearSpeakMessageResponse: (
    state: ChatState,
    action: PayloadAction<string>
  ) => {
    const token = action.payload;
    if (state.speakMessages[token]) {
      const speakMessage = state.speakMessages[token];
      state.speakMessages[token] = { input: speakMessage.input, responses: [], references: [] };
    }
  },
};

const chatVisibilityReducers = {
  showChat: (state: ChatState) => {
    state.isChatVisible = true;
  },
  hideChat: (state: ChatState) => {
    state.isChatVisible = false;
  },
};

const tokenReducers = {
  resetToken: (state: ChatState) => {
    state.token = createFormToken();
  },
  clearToken: (state: ChatState) => {
    state.token = "";
  },
  setToken: (state: ChatState, action: PayloadAction<string>) => {
    state.token = action.payload;
  }
}

const isChatAllowedReducers = {
  setIsChatAllowed: (state: ChatState, action: PayloadAction<boolean>) => {
    state.isChatAllowed = action.payload;
  }
}

const createFormToken = () => (crypto.randomUUID === undefined) ? generateRandomString(20) : crypto.randomUUID();
const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const isPrivateChatReducers = {
  setIsPrivateChat: (state: ChatState, action: PayloadAction<boolean>) => {
    state.isPrivateChat = action.payload;
  }
}

const chatSlice = createSlice<ChatState, any, "chat">({
  name: "chat",
  initialState,
  reducers: {
    ...isLoadingReducers,
    ...speakInputValueReducers,
    ...speakMessagesReducers,
    ...chatVisibilityReducers,
    ...isCreatingReducers,
    ...formTokenReducers,
    ...aiReducers,
    ...aiFormReducers,
    ...userReducers,
    ...initialFormValuesReducers,
    ...threadReducers,
    ...logsReducers,
    ...tokenReducers,
    ...isChatAllowedReducers,
    ...isPrivateChatReducers,
  },
});

export const {
  setIsLoading,
  clearAllSpeakMessages,
  addSpeakMessage,
  addSpeakResponse,
  deleteSpeakMessage,
  clearSpeakMessageResponse,
  showChat,
  hideChat,
  startGenerating,
  stopGenerating,
  setAi,
  setAiForms,
  setUser,
  setThread,
  setInitialFormValues,
  resetInitialFormValues,
  addSpeakInputAndResponse,
  setUserHistories,
  setOtherHistories,
  clearLogs,
  addSpeakReferences,
  resetToken,
  clearToken,
  setToken,
  setIsChatAllowed,
  setIsPrivateChat,
} = chatSlice.actions;

export default chatSlice.reducer;