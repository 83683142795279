import getClient from "../client";
import { AxiosResponse } from "axios";
import { Thread } from "@/interfaces/biz/users_ai";

/**
 * スレッドの公開・非公開ステータスを更新するAPI
 * @param threadId - スレッドのID
 * @param isPrivate - 非公開にするかどうかのフラグ
 * @returns 更新後のスレッドデータを返す
 */
export const updateThreadPrivacy = async (
  threadId: string,
  isPrivate: boolean
): Promise<AxiosResponse<Thread>> => {
  return getClient().patch(`/biz/threads/${threadId}/update_privacy.json`, {
    is_private: isPrivate,
  });
};
